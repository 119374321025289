import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <main>
        <h1>hi, i'm preston rakovsky</h1>
        <p>trying to make the world a more vulnerable place</p>
        <ul className="projects">
          <li><a href="https://instagram.com/prestonrack" target="_blank" rel="noopener noreferrer">videos</a></li>
          <li><a href="https://www.getsoulapp.com" target="_blank" rel="noopener noreferrer">soul</a></li>
        </ul>
      </main>
    </div>
  );
}

export default App;
